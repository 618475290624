import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

interface AlertData {
  message: string;
  type?: 'success';
  desc?: string;
  cta?: string;
  onOK?: () => void;
}

export const useAlertStore = defineStore('alert', () => {
  const data = ref<AlertData | null>(null);

  const setData = (value: AlertData) => (data.value = value);

  const show = (
    message: AlertData['message'],
    options?: Partial<AlertData>
  ) => {
    setData({ message, ...options });
  };

  const success = (
    message: AlertData['message'],
    options?: Partial<AlertData>
  ) => {
    show(message, { type: 'success', ...options });
  };

  const reset = () => (data.value = null);

  const requestError = (error: AxiosError, options?: Partial<AlertData>) => {
    const message = get(error, 'response.data.error.message') || error.message;

    show(message, options);
    console.warn(error);
  };

  const requestSuccess = (
    response: AxiosResponse,
    options?: Partial<AlertData>
  ) => {
    const message = get(response, 'data.data.message');
    success(message, options);
  };

  return { data, show, success, reset, requestError, requestSuccess };
});
